import { Link } from "react-router-dom";
import WaveBackground from "./utils/WaveBackground";
import ShareButtons from "./utils/ShareButtons";

const Footer = () => {
  return (
    <>
      <WaveBackground />
      <footer className="bg-white flex flex-col items-center justify-center">
        <ShareButtons />
        <div className="container mx-auto px-4 py-4 text-center text-gray-600">
          &copy; 2024{" "}
          <Link className="text-green-600" to="/apropos">
            AJP.
          </Link>{" "}
          Tous droits réservés. Offert par{" "}
          <Link
            className="text-green-800"
            to="https://success-com.abassdev.com"
          >
            Success.com
          </Link>
        </div>
      </footer>
    </>
  );
};

export default Footer;
