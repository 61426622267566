import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./Footer";

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [serverMessage, setServerMessage] = useState("");
  const [errors, setErrors] = useState({
    email: false,
    password: false,
  });
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is authenticated
    const token = localStorage.getItem("token");
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {
      email: !email,
      password: !password,
    };
    setErrors(newErrors);

    if (Object.values(newErrors).includes(true)) {
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/login`,
        {
          email,
          password,
        }
      );

      const { token } = response.data;

      // Store JWT token in local storage
      localStorage.setItem("token", token);
      setIsAuthenticated(true);

      setServerMessage("Connexion réussie");
      navigate("/membres");
    } catch (error) {
      setServerMessage("Mot de passe ou email invalide");
    }
  };

  const handleChange = (e, field) => {
    const value = e.target.value;
    if (field === "email") setEmail(value);
    if (field === "password") setPassword(value);

    setErrors((prev) => ({ ...prev, [field]: false }));
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    navigate("/admin-login");
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <header className="bg-white shadow">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <Link to="/" className="flex items-center">
            <img src="./logo.png" alt="AJP Logo" className="h-20 w-20 mr-3" />
            <div>
              <h1 className="text-2xl font-bold text-gray-800 hidden sm:block">
                AJP
              </h1>
              <h2 className="text-lg text-gray-600 hidden sm:block">
                Les volontaires pour la formation en secourisme.
              </h2>
            </div>
          </Link>
          <nav className="space-x-4">
            <Link to="/" className="text-gray-800 hover:text-gray-600">
              Accueil
            </Link>
            <Link
              to="/rejoignez-nous"
              className="text-gray-800 hover:text-gray-600"
            >
              Rejoignez-nous
            </Link>
            {isAuthenticated && (
              <button
                onClick={handleLogout}
                className="text-gray-800 hover:text-gray-600"
              >
                Déconnexion
              </button>
            )}
          </nav>
        </div>
      </header>
      <main className="flex-grow container mx-auto px-4 py-8">
        <div className="max-w-lg mx-auto bg-white p-6 rounded-lg shadow-md">
          <header className="text-center mb-6">
            <h2 className="text-2xl font-semibold text-gray-800">
              Connexion Administrateur
            </h2>
          </header>
          <p className="bg-green-100 p-1 mb-5 rounded-lg shadow-md text-center text-green-800 font-semibold">
            Vous devez être connecté en tant qu'administrateur pour accéder à la
            liste des membres.
          </p>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="form-group">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => handleChange(e, "email")}
                className={`w-full p-3 border rounded-lg ${
                  errors.email ? "border-red-500" : "border-gray-300"
                } focus:outline-none focus:ring-2 focus:ring-green-500`}
              />
              {errors.email && (
                <div className="text-red-500 text-sm mt-1">
                  L'email est requis
                </div>
              )}
            </div>
            <div className="form-group">
              <input
                type="password"
                placeholder="Mot de passe"
                value={password}
                onChange={(e) => handleChange(e, "password")}
                className={`w-full p-3 border rounded-lg ${
                  errors.password ? "border-red-500" : "border-gray-300"
                } focus:outline-none focus:ring-2 focus:ring-green-500`}
              />
              {errors.password && (
                <div className="text-red-500 text-sm mt-1">
                  Le mot de passe est requis
                </div>
              )}
            </div>
            <button
              type="submit"
              className="w-full py-3 bg-green-800 text-white font-semibold rounded-lg hover:bg-green-600 transition duration-300"
            >
              Se connecter
            </button>
            {serverMessage && (
              <div className="text-red-500 text-center mt-4">
                {serverMessage}
              </div>
            )}
          </form>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default AdminLogin;
