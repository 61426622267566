import React from "react";

const WhatsAppLink = ({ phoneNumber, message }) => {
  // Create the WhatsApp link with the phone number and message
  const encodedMessage = encodeURIComponent(message);
  const whatsappLink = `https://wa.me/+227${phoneNumber}?text=${encodedMessage}`;

  return (
    <a
      className="text-green-600"
      href={whatsappLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      +227{phoneNumber}
    </a>
  );
};

export default WhatsAppLink;
