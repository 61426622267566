import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import WhatsAppLink from "./utils/WhatsAppLink";

const MembersPage = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [regions, setRegions] = useState([]);
  const [departements, setDepartements] = useState([]);
  const [regionCounts, setRegionCounts] = useState({});
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedDepartement, setSelectedDepartement] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [error, setError] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isRegionTableVisible, setIsRegionTableVisible] = useState(false); // State for toggling region table visibility
  const [communes, setCommunes] = useState([]);
  const [selectedCommune, setSelectedCommune] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/admin-login");
      } else {
        setIsAuthenticated(true);
      }
    };
    checkAuth();
  }, [navigate]);

  useEffect(() => {
    if (isAuthenticated) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/users`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        })
        .then((response) => {
          const userData = response.data;
          setUsers(userData);
          setFilteredUsers(userData);

          const uniqueRegions = [
            ...new Set(userData.map((user) => user.region)),
          ];
          setRegions(uniqueRegions);

          const uniqueDepartements = [
            ...new Set(userData.map((user) => user.departement)),
          ];
          setDepartements(uniqueDepartements);

          const uniqueCommunes = [
            ...new Set(userData.map((user) => user.commune)),
          ];
          setCommunes(uniqueCommunes); // Ajouter cette ligne

          const regionCounts = uniqueRegions.reduce((acc, region) => {
            acc[region] = userData.filter(
              (user) => user.region === region
            ).length;
            return acc;
          }, {});
          setRegionCounts(regionCounts);
        })
        .catch((err) => {
          console.error("Error fetching users:", err);
          setError("Erreur lors de la récupération des utilisateurs");
        });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    let filtered = users;
    if (selectedRegion) {
      filtered = filtered.filter((user) => user.region === selectedRegion);
    }
    if (selectedDepartement) {
      filtered = filtered.filter(
        (user) => user.departement === selectedDepartement
      );
    }
    if (selectedCommune) {
      filtered = filtered.filter((user) => user.commune === selectedCommune); // Ajouter ce filtrage
    }
    if (searchQuery) {
      filtered = filtered.filter((user) =>
        [user.name, user.surname, user.phone].some((field) =>
          field.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }
    setFilteredUsers(filtered);
  }, [
    selectedRegion,
    selectedDepartement,
    selectedCommune,
    searchQuery,
    users,
  ]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    navigate("/admin-login");
  };

  const toggleRegionTable = () => {
    setIsRegionTableVisible(!isRegionTableVisible);
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      <header className="bg-white shadow">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <Link to="/" className="flex items-center">
            <img src="./logo.png" alt="AJP Logo" className="h-20 w-20 mr-3" />
            <div>
              <h1 className="text-sm sm:text-2xl font-bold text-green-800 sm:block">
                AJP
              </h1>
              <h2 className="text-sm sm:text-lg text-gray-600 sm:block">
                Alliance des
                <br /> Jeunes Patriotes
              </h2>
            </div>
          </Link>
          <nav className="space-x-4">
            <Link to="/" className="text-gray-800 hover:text-gray-600">
              Accueil
            </Link>
            {isAuthenticated && (
              <button
                onClick={handleLogout}
                className="text-gray-800 hover:text-gray-600"
              >
                Déconnexion
              </button>
            )}
          </nav>
        </div>
      </header>

      <main className="flex-grow container mx-auto px-4 py-8">
        <header className="text-center mb-6">
          <h1 className="text-2xl font-semibold text-gray-800">
            Liste des membres inscrits
          </h1>
          {error && <p className="text-red-900 py-4">{error} </p>}
        </header>
        {/* Button to Toggle Region Count Table */}
        <div className="text-center mb-6">
          <button
            onClick={toggleRegionTable}
            className="px-4 py-2 bg-green-800 text-white rounded hover:bg-green-600 transition"
          >
            {isRegionTableVisible
              ? "Masquer le nombre des members par région"
              : "Afficher le nombre des members par région"}
          </button>
        </div>
        {/* Region Count Table */}
        {isRegionTableVisible && (
          <div className="max-w-4xl mx-auto mb-6 p-5 bg-gray-100 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              Nombre de members par région
            </h2>
            <table className="min-w-full divide-y divide-gray-200 bg-white rounded-lg shadow-md">
              <thead>
                <tr>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Région
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Nombre members
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {regions.map((region) => (
                  <tr key={region}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {region}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {regionCounts[region] || 0}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div className="max-w-4xl mx-auto mb-6 p-5 bg-gray-100 rounded-lg shadow-md">
          {/* Search Input */}
          <div className="mb-6">
            <label
              htmlFor="searchInput"
              className="block text-gray-700 font-medium mb-2"
            >
              Rechercher:
            </label>
            <input
              id="searchInput"
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Rechercher par nom, prénom ou téléphone"
              className="w-full p-3 border rounded bg-white"
            />
          </div>

          {/* Filters */}
          <div className="flex flex-col md:flex-row md:space-x-4">
            <div className="mb-4 md:mb-0 flex-1">
              <label
                htmlFor="regionFilter"
                className="block text-gray-700 font-medium mb-2"
              >
                Filtrer par Région:
              </label>
              <select
                id="regionFilter"
                value={selectedRegion}
                onChange={(e) => {
                  setSelectedRegion(e.target.value);
                  setSelectedDepartement(""); // Reset department filter when region changes
                }}
                className="block w-full p-3 border rounded bg-white focus:outline-none focus:ring-2 focus:ring-green-500"
              >
                <option value="">Toutes les régions</option>
                {regions.map((region, index) => (
                  <option key={index} value={region}>
                    {region}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex-1">
              <label
                htmlFor="departementFilter"
                className="block text-gray-700 font-medium mb-2"
              >
                Filtrer par Département:
              </label>
              <select
                id="departementFilter"
                value={selectedDepartement}
                onChange={(e) => setSelectedDepartement(e.target.value)}
                className="block w-full p-3 border rounded bg-white focus:outline-none focus:ring-2 focus:ring-green-500"
              >
                <option value="">Tous les départements</option>
                {departements.map((dept, index) => (
                  <option key={index} value={dept}>
                    {dept}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex-1">
              <label
                htmlFor="communeFilter"
                className="block text-gray-700 font-medium mb-2"
              >
                Filtrer par Commune:
              </label>
              <select
                id="communeFilter"
                value={selectedCommune}
                onChange={(e) => setSelectedCommune(e.target.value)}
                className="block w-full p-3 border rounded bg-white focus:outline-none focus:ring-2 focus:ring-green-500"
              >
                <option value="">Toutes les communes</option>
                {communes.map((commune, index) => (
                  <option key={index} value={commune}>
                    {commune}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {/* User Details Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 bg-white rounded-lg shadow-md">
            <thead>
              <tr>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  ID
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Nom
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Prénom
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Téléphone
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Région
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Département
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Commune
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredUsers.length > 0 ? (
                filteredUsers.map((user) => (
                  <tr key={user.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      <Link to={`/user/${user.id}`}>{user.id}</Link>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      <Link className="text-green-600" to={`/user/${user.id}`}>
                        {user.name}
                      </Link>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <Link className="text-green-600" to={`/user/${user.id}`}>
                        {user.surname}
                      </Link>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <WhatsAppLink message={""} phoneNumber={user.phone}>
                        {user.phone}
                      </WhatsAppLink>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {user.region}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {user.departement}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {user.commune}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="7"
                    className="px-6 py-4 text-center text-sm font-medium text-gray-500"
                  >
                    Aucun member trouvé
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default MembersPage;
