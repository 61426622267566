import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import validatePhone from "./utils/validatePhone";
import Footer from "./Footer";
import communes from "./utils/communes";
import departements from "./utils/departements";
import regions from "./utils/regions";
import getDeviceName from "./utils/getDeviceName";

const RejoignezNous = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phone, setPhone] = useState("");
  const [region, setRegion] = useState("");
  const [departement, setDepartement] = useState("");
  const [commune, setCommune] = useState("");
  const [serverMessage, setServerMessage] = useState("");
  const [errors, setErrors] = useState({
    name: false,
    surname: false,
    phone: false,
    region: false,
    departement: false,
    commune: false,
  });
  const [loading, setLoading] = useState(false); // New state for loading

  const navigate = useNavigate();

  useEffect(() => {
    setDepartement(""); // Reset department when region changes
    setCommune(""); // Reset commune when department changes
  }, [region]);

  useEffect(() => {
    setCommune(""); // Reset commune when department changes
  }, [departement]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {
      name: !name,
      surname: !surname,
      phone: !validatePhone(phone),
      region: !region,
      departement: !departement,
      commune: !commune,
    };
    setErrors(newErrors);

    if (Object.values(newErrors).includes(true)) {
      return;
    }

    setLoading(true); // Start loading

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/submit`,
        {
          name,
          surname,
          phone,
          region,
          departement,
          commune,
          deviceName: getDeviceName(),
        }
      );

      setServerMessage("Données soumises avec succès");
      Cookies.set(
        "user",
        JSON.stringify({ name, surname, phone, region, departement, commune }),
        {
          expires: 365 * 20, // 20 years
        }
      );

      // Clear fields after submission
      setName("");
      setSurname("");
      setPhone("");
      setRegion("");
      setDepartement("");
      setCommune("");

      // Redirect to profile page on success
      navigate("/profile");
    } catch (error) {
      if (error.response) {
        if (error.response.status === 409) {
          setServerMessage("Le numéro de téléphone est déjà utilisé.");
        } else {
          setServerMessage("Erreur lors de la soumission des données");
        }
      } else {
        setServerMessage("Erreur de connexion au serveur");
      }
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleChange = (e, field) => {
    const value = e.target.value;
    if (field === "name") setName(value);
    if (field === "surname") setSurname(value);
    if (field === "phone") setPhone(value);
    if (field === "region") setRegion(value);
    if (field === "departement") setDepartement(value);
    if (field === "commune") setCommune(value);

    setErrors((prev) => ({ ...prev, [field]: false }));
  };

  const user = Cookies.get("user");

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <header className="bg-white shadow">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <Link to="/" className="flex items-center">
            <img src="./logo.png" alt="AJP Logo" className="h-20 w-20 mr-3" />
            <div>
              <h1 className="text-sm sm:text-2xl font-bold text-green-800 sm:block">
                AJP
              </h1>
              <h2 className="text-sm sm:text-lg text-gray-600 sm:block">
                Alliance des
                <br /> Jeunes Patriotes
              </h2>
            </div>
          </Link>
          <nav className="space-x-4">
            <a href="/" className="text-gray-800 hover:text-gray-600">
              Accueil
            </a>

            <a href="/membres" className="text-gray-800 hover:text-gray-600">
              Membres
            </a>
            {user && (
              <a href="/profile" className="text-gray-800 hover:text-gray-600">
                Profil
              </a>
            )}
          </nav>
        </div>
      </header>
      <main className="flex-grow container mx-auto px-4 py-8">
        <div className="max-w-lg mx-auto bg-white p-6 rounded-lg shadow-md">
          <header className="text-center mb-6">
            <h2 className="text-2xl mb-2 font-semibold text-gray-800">
              Les volontaires pour la formation en secourisme.
            </h2>

            <p className="text-gray-600">
              Pour votre inscription, Veuillez remplir les informations
              ci-dessous.
            </p>
          </header>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="form-group">
              <input
                type="text"
                placeholder="Nom"
                value={name}
                onChange={(e) => handleChange(e, "name")}
                className={`w-full p-3 border rounded-lg ${
                  errors.name ? "border-red-500" : "border-gray-300"
                } focus:outline-none focus:ring-2 focus:ring-green-500`}
              />
              {errors.name && (
                <div className="text-red-500 text-sm">
                  Ce champ est obligatoire
                </div>
              )}
            </div>
            <div className="form-group">
              <input
                type="text"
                placeholder="Prénom"
                value={surname}
                onChange={(e) => handleChange(e, "surname")}
                className={`w-full p-3 border rounded-lg ${
                  errors.surname ? "border-red-500" : "border-gray-300"
                } focus:outline-none focus:ring-2 focus:ring-green-500`}
              />
              {errors.surname && (
                <div className="text-red-500 text-sm">
                  Ce champ est obligatoire
                </div>
              )}
            </div>
            <div className="form-group">
              <input
                type="text"
                placeholder="Numéro de téléphone"
                value={phone}
                onChange={(e) => handleChange(e, "phone")}
                className={`w-full p-3 border rounded-lg ${
                  errors.phone ? "border-red-500" : "border-gray-300"
                } focus:outline-none focus:ring-2 focus:ring-green-500`}
              />
              {errors.phone && (
                <div className="text-red-500 text-sm">
                  {errors.phone === true
                    ? "Numéro de téléphone invalide"
                    : "Ce champ est obligatoire"}
                </div>
              )}
            </div>
            <div className="form-group">
              <select
                value={region}
                onChange={(e) => handleChange(e, "region")}
                className={`w-full p-3 border rounded-lg ${
                  errors.region ? "border-red-500" : "border-gray-300"
                } focus:outline-none focus:ring-2 focus:ring-green-500`}
              >
                <option value="">Sélectionner une région</option>
                {regions.map((region) => (
                  <option key={region} value={region}>
                    {region}
                  </option>
                ))}
              </select>
              {errors.region && (
                <div className="text-red-500 text-sm">
                  Ce champ est obligatoire
                </div>
              )}
            </div>
            <div className="form-group">
              <select
                value={departement}
                onChange={(e) => handleChange(e, "departement")}
                className={`w-full p-3 border rounded-lg ${
                  errors.departement ? "border-red-500" : "border-gray-300"
                } focus:outline-none focus:ring-2 focus:ring-green-500`}
              >
                <option value="">Sélectionner un département</option>
                {region &&
                  (departements[region] || []).map((dept) => (
                    <option key={dept} value={dept}>
                      {dept}
                    </option>
                  ))}
              </select>
              {errors.departement && (
                <div className="text-red-500 text-sm">
                  Ce champ est obligatoire
                </div>
              )}
            </div>
            <div className="form-group">
              <label name="commune">Commune</label>

              <input
                name="commune"
                value={commune}
                onChange={(e) => handleChange(e, "commune")}
                className={`w-full p-3 border rounded-lg ${
                  errors.commune ? "border-red-500" : "border-gray-300"
                } focus:outline-none focus:ring-2 focus:ring-green-500`}
              />
              {errors.commune && (
                <div className="text-red-500 text-sm">
                  Ce champ est obligatoire
                </div>
              )}
            </div>
            <div className="flex items-center justify-between">
              <button
                type="submit"
                className="w-full p-3 bg-green-500 text-white rounded-lg focus:outline-none hover:bg-green-600 disabled:opacity-50"
                disabled={loading} // Disable button when loading
              >
                {loading ? (
                  <span className="flex justify-center items-center">
                    <svg
                      className="animate-spin h-5 w-5 mr-3 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v2a6 6 0 00-6 6h2zm4 8a8 8 0 01-8-8h2a6 6 0 006 6v2zm8-8a8 8 0 01-8 8v-2a6 6 0 006-6h2zm-4-8a8 8 0 018 8h-2a6 6 0 00-6-6V4z"
                      ></path>
                    </svg>
                    Chargement...
                  </span>
                ) : (
                  "Soumettre"
                )}
              </button>
              {serverMessage && (
                <div className="text-red-500 text-sm">{serverMessage}</div>
              )}
            </div>
          </form>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default RejoignezNous;
