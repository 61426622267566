import React, { useEffect } from "react";
import "./index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./HomePage";
import ProfilePage from "./ProfilePage";
import RejoignezNous from "./RejoignezNous";
import MembersPage from "./MembersPage";
import AdminSignup from "./AdminSignup";
import AdminLogin from "./AdminLogin";

import { logPageView } from "./utils/analytics"; // Import the logPageView function
import AboutPage from "./AboutPage";
import UserDetailsPage from "./UserDetailsPage";
import EditProfile from "./EditProfile";

const App = () => {
  useEffect(() => {
    logPageView(); // Log the initial page load
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/rejoignez-nous" element={<RejoignezNous />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/membres/edit/:id" element={<EditProfile />} />
        <Route path="/membres" element={<MembersPage />} />
        <Route path="/members" element={<MembersPage />} />
        <Route path="/admin-signup" element={<AdminSignup />} />
        <Route path="/admin-login" element={<AdminLogin />} />
        <Route path="/apropos" element={<AboutPage />} />
        <Route path="/user/:userId" element={<UserDetailsPage />} />
      </Routes>
    </Router>
  );
};

export default App;
