const departements = {
  Agadez: [
    "Bilma",
    "Tchirozérine",
    "Arlit",
    "Aderbissinat",
    "Iférouane",
    "Ingall",
  ],
  Diffa: ["Bosso", "N'guigmi", "Maine-Soroa", "Goudoumaria", "N'Gourti"],
  Dosso: [
    "Boboye",
    "Dioundiou",
    "Dogondoutchi",
    "Falmèy",
    "Gaya",
    "Loga",
    "Tibiri",
  ],
  Maradi: [
    "Aguié",
    "Bermo",
    "Dakoro",
    "Gazaoua",
    "Guidan-Roumdji",
    "Madarounfa",
    "Mayahi",
    "Tessaoua",
    "Ville de Maradi",
  ],
  Tahoua: [
    "Abalak",
    "Bagaroua",
    "Birni N'Konni",
    "Bouza",
    "	Illéla",
    "Kéita",
    "Madaoua",
    "Malbaza",
    "Tassara",
    "Tchintabaraden",
    "Tillia",
    "Ville de Tahoua",
  ],
  Tillabéri: [
    "Abala",
    "Ayérou",
    "Balleyara",
    "Banibangou",
    "Bankilaré",
    "Filingué",
    "Gothèye",
    "Kollo",
    "Ouallam",
    "Say",
    "Téra",
    "Torodi",
  ],
  Zinder: [
    "Belbédji",
    "Damagaram Takaya",
    "Dungass",
    "Gouré",
    "Kantché",
    "Magaria",
    "Mirriah",
    "Takeita",
    "Tanout",
    "Tesker",
    "Ville de Zinder",
  ],
  Niamey: ["Ville de Niamey"],
};

export default departements;
