import React from "react";

const WaveBackground = () => {
  const [isSmallScreen, setIsSmallScreen] = React.useState(
    window.innerWidth < 768
  );

  React.useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <svg
      className="z-index-0 inset-x-0 bottom-0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={isSmallScreen ? "0 0 540 320" : "0 0 1140 320"}
      fill="#ffffff"
      preserveAspectRatio="none"
    >
      <path
        fillOpacity="1"
        d="M0,64L30,85.3C60,107,120,149,180,149.3C240,149,300,107,360,101.3C420,96,480,128,540,160C600,192,660,224,720,245.3C780,267,840,277,900,266.7C960,256,1020,224,1080,213.3C1140,203,1200,213,1260,213.3C1320,213,1380,203,1410,197.3L1440,192L1440,320L0,320Z"
      />
    </svg>
  );
};
export default WaveBackground;
