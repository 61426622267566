import React from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const AboutPage = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-white shadow">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <Link to="/" className="flex items-center">
            <img src="./logo.png" alt="AJP Logo" className="h-20 w-20 mr-3" />
            <div>
              <h1 className="text-sm sm:text-2xl font-bold text-green-800 sm:block">
                AJP
              </h1>
              <h2 className="text-sm sm:text-lg text-gray-600 sm:block">
                Alliance des
                <br /> Jeunes Patriotes
              </h2>
            </div>
          </Link>
          <nav className="space-x-4">
            <a href="/" className="text-gray-800 hover:text-gray-600">
              Accueil
            </a>
            <a
              href="/rejoignez-nous"
              className="text-gray-800 hover:text-gray-600"
            >
              Inscrivez-vous
            </a>
          </nav>
        </div>
      </header>

      <main className="flex-grow container mx-auto px-4 py-8 max-w-2xl">
        <section className="bg-white p-6 rounded-lg shadow-md">
          <header className="text-center mb-6">
            <h1 className="text-2xl md:text-3xl font-semibold text-gray-800">
              À Propos de L'AJP
            </h1>
            <p className="text-base md:text-lg text-gray-600"></p>
          </header>

          <div className="space-y-4 md:space-y-6">
            <p className="text-base md:text-lg text-gray-700">
              L'Alliance des Jeunes Patriotes (AJP) est un réseau/coalition de
              plusieurs ONG et associations de développement au Niger, qui œuvre
              dans plusieurs domaines : paix et cohésion sociale, jeunesse et
              entrepreneuriat, développement rural, agriculture... pour ne citer
              que ceux-là.
            </p>
            <p className="text-base md:text-lg text-gray-700">
              C’est un réseau organisé et bien structuré dont les structures
              membres exécutent des projets et programmes depuis des décennies.
              À travers nos différents postes, nous visons un changement de
              comportement, non seulement chez les jeunes, mais aussi au sein de
              toutes les couches sociales. Nous voulons amener les jeunes à
              comprendre l'importance de cultiver un esprit de paix et de
              citoyenneté responsable. Nous sommes situés au quartier Banifandou
              2.
            </p>
            <p className="text-base md:text-lg text-gray-700">
              Nous croyons fermement en la puissance de l'action collective et
              en la capacité des jeunes à mener des changements positifs. À
              travers divers programmes, événements et initiatives, nous visons
              à encourager les jeunes à s'impliquer activement dans les affaires
              publiques, à promouvoir la justice sociale, et à renforcer le
              tissu social de notre communauté.
            </p>
            <p className="text-base md:text-lg text-gray-700">
              Notre engagement se reflète dans nos actions quotidiennes, et nous
              nous efforçons de créer un environnement inclusif et inspirant où
              chaque voix peut être entendue et où chaque individu peut avoir un
              impact. Rejoignez-nous dans notre mission pour un avenir meilleur
              et plus juste pour tous.
            </p>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default AboutPage;
