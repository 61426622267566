import React from "react";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import Footer from "./Footer";
const HomePage = () => {
  const user = Cookies.get("user");
  console.log(process.env);
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col relative overflow-hidden">
      <header className="bg-white shadow z-10 relative">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <Link to="/" className="flex items-center">
            <img src="./logo.png" alt="AJP Logo" className="h-20 w-20 mr-3" />
            <div>
              <h1 className="text-sm sm:text-2xl font-bold text-green-800 sm:block">
                AJP
              </h1>
              <h2 className="text-sm sm:text-lg text-gray-600 sm:block">
                Alliance des
                <br /> Jeunes Patriotes
              </h2>
            </div>
          </Link>
          <nav className="space-x-4">
            <a href="/membres" className="text-gray-800 hover:text-gray-600">
              Membres
            </a>
            {user && (
              <a href="/profile" className="text-gray-800 hover:text-gray-600">
                Profil
              </a>
            )}
          </nav>
        </div>
      </header>
      <main className="flex-grow container mx-auto px-4 py-8 max-w-3xl relative z-10">
        <div className="text-center">
          <h2 className="text-xl md:text-2xl font-bold text-green-800 mb-4">
            Bienvenue sur le site de l'AJP
          </h2>
          <p className="text-base md:text-lg text-gray-600 mb-8">
            Les volontaires pour la formation en secourisme
          </p>

          <p className="text-lg md:text-lg text-green-800">
            Veuillez cliquer sur le bouton en bas pour vous inscrire à la
            formation en secourisme.
          </p>

          <button className="mt-6 bg-green-800 text-white font-bold py-2 px-4 rounded-lg hover:bg-green-600">
            <a href="/rejoignez-nous">Inscrivez-vous dès aujourd'hui !</a>
          </button>
        </div>
      </main>

      {/* <AdSenseAd /> */}
      <Footer />
    </div>
  );
};

export default HomePage;
