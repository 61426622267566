const communes = {
  Goudoumaria: ["Goudoumaria"],
  Aderbissinat: ["Aderbissinat"],
  Arlit: ["Arlit", "Dannet", "Gougaram"],
  Bilma: ["Dirkou", "Djado", "Fachi"],
  "N'Gourti": ["N'Gourti"],
  Iférouane: ["Timia"],
  Ingall: ["Ingall"],
  Tchirozérine: ["Dabaga", "Tabelot"],

  Boboye: [
    "Birni N'Gaouré",
    "Fabidji",
    "Fakara",
    "Harikanassou",
    "Kankandi",
    "Kiota",
    "Koygolo",
    "N'Gonga",
  ],
  Dioundiou: ["Karakara", "Zabori"],
  Dogondoutchi: [
    "Dan-Kassari",
    "Dogonkiria",
    "Kiéché",
    "Matankari",
    "Soucoucoutane",
  ],
  Dosso: [
    "Farey",
    "Garankédey",
    "Gollé",
    "Goroubankassam",
    "Karguibangou",
    "Mokko",
    "Sambéra",
    "Tombokoirey I",
    "Tombokoirey II",
  ],
  Falmèy: ["Guilladjé"],
  Gaya: ["Bana", "Bengou", "Tanda", "Tounouga", "Yélou"],
  Loga: ["Falouel", "Sokorbé"],
  Tibiri: ["Douméga", "Guéchémé", "Koré Maïroua"],

  Bosso: ["Toumour"],

  "Maine-Soroa": ["Foulatari", "N'Guelbély"],
  Ayorou: ["Bankilaré", "Filingue", "Ouallam", "Sanam", "Téra"],

  "N'guigmi": ["Kabléwa"],
  Aguié: ["Aguié", "Tchadoua"],
  Bermo: ["Bermo", "Gadabédji"],
  Dakoro: [
    "Dakoro",
    "Adjékoria",
    "Azagor",
    "Bader Goula",
    "Birni Lallé",
    "Dan-Goulbi",
    "Korahane",
    "Kornaka",
    "Maïyara",
    "Roumbou I",
    "Sabon-Machi",
    "Tagriss",
  ],
  Gazaoua: ["Gazaoua", "Gangara"],
  "Guidan-Roumdji": [
    "Guidan-Roumdji",
    "Tibiri",
    "Chadakori",
    "Guidan Sori",
    "Saé Saboua",
  ],
  Madarounfa: [
    "Madarounfa",
    "Dan-Issa",
    "Djirataoua",
    "Gabi",
    "Safo",
    "Sarkin Yamma",
  ],
  Mayahi: [
    "Mayahi",
    "Attantané",
    "El Allassane Maïreyrey",
    "Guidan Amoumoune",
    "Issaouane",
    "Kanan-Bakaché",
    "Sarkin Haoussa",
    "Tchaké",
  ],
  Tessaoua: [
    "Tessaoua",
    "Baoudetta",
    "Hawandawaki",
    "Koona",
    "Korgom",
    "Maïjirgui",
    "Ourafane",
  ],
  "Ville de Maradi": ["Maradi I", "Maradi II", "Maradi III"],

  // Zinder
  Belbédji: ["Tarka"],
  "Damagaram Takaya": [
    "Damagaram Takaya",
    "Albarkaram",
    "Guidimouni",
    "Mazamni",
    "Moa",
    "Wamé",
  ],
  Dungass: ["Dogo-Dogo", "Dungass", "Gouchi", "Mallaoua"],
  Gouré: ["Alakoss", "Bouné", "Gamou", "Gouré", "Guidiguir", "Kellé"],
  Kantché: [
    "Dan-Barto",
    "Daouché",
    "Doungou",
    "Ichirnawa",
    "Kantché",
    "Kourni",
    "Matamèye",
    "Tsaouni",
    "Yaouri",
  ],
  Magaria: [
    "Bandé",
    "Dantchiao",
    "Kouaya",
    "Magaria",
    "Sassoumbroum",
    "Ouacha (Wacha)",
    "Yékoua",
  ],
  Mirriah: [
    "Dogo",
    "Droum",
    "Gaffati",
    "Gouna",
    "Hamdara",
    "Kolléram",
    "Mirriah",
    "Zermou",
  ],
  Takeita: ["Dakoussa", "Garagoumsa", "Tirmini"],
  Tanout: ["Falenko", "Gangara", "Ollelewa", "Tanout", "Tenhya"],
  Tesker: ["Tesker"],
  "Ville de Zinder": [
    "Zinder I",
    "Zinder II",
    "Zinder III",
    "Zinder IV",
    "Zinder V",
  ],

  // 	Tahoua

  Abalak: ["Akoubounou", "Azèye", "Tabalak", "Tamaya"],
  Bagaroua: ["Bagaroua"],
  "Birni N'Konni": ["Alléla", "Bazaga", "Doguérawa", "Tsernaoua"],
  Bouza: ["Allakaye", "Babankatami", "Déoulé", "Karofane", "Tabotaki", "Tama"],
  Illéla: ["Badaguichiri", "Tajaé"],
  Kéita: ["Garhanga", "Ibohamane", "Tamaské"],
  Madaoua: ["Azarori", "Bangui", "Galma Koudawatché", "Ourno", "Sabon Guida"],
  Tassara: ["Tassara"],
  Tchintabaraden: ["Kao"],
  Tillia: ["Tillia"],
  // Tillabéri
  Abala: ["Abala", "Sanam"],
  Ayérou: ["Ayérou", "Inatès"],
  Balleyara: ["Tagazar"],
  Banibangou: ["Bani Bangou"],
  Bankilaré: ["Bankilaré"],
  Filingué: ["Filingué", "Imanan", "Kourfeye Centre", "Tondikandia"],
  Gothèye: ["Gothèye", "Dargol"],
  Kollo: [
    "Kollo",
    "Bitinkodji",
    "Diantchandou",
    "Hamdallaye",
    "Karma",
    "Kirtachi",
    "Kouré",
    "Liboré",
    "N'Dounga",
    "Namaro",
    "Youri",
  ],
  Ouallam: ["Ouallam", "Dingazi", "Simiri", "Tondikiwindi"],
  Say: ["Say", "Ouro Guéladjo", "Tamou"],
  Téra: ["Téra", "Diagourou", "Gorouol", "Kokorou", "Méhana"],
  Tillabéri: [
    "Tillabéri",
    "Anzourou",
    "Bibiyergou",
    "Dessa",
    "Kourtèye",
    "Sakoira",
    "Sinder",
  ],
  Torodi: ["Torodi", "Makalondi"],
  "Ville de Niamey": [
    "Niamey I",
    "Niamey II",
    "Niamey III",
    "Niamey IV",
    "Niamey V",
  ],
};

export default communes;
