import React from "react";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./Footer";

const ProfilePage = () => {
  const user = Cookies.get("user");
  const navigate = useNavigate();

  const handleLogout = () => {
    Cookies.remove("user");
    navigate("/");
  };

  if (!user) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <p className="text-gray-700">
          Vous n'êtes pas connecté.{" "}
          <a href="/" className="text-blue-500 underline">
            Retour à l'accueil
          </a>
        </p>
      </div>
    );
  }

  const userData = JSON.parse(user);
  console.log(userData);
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <header className="bg-white shadow">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <Link to="/" className="flex items-center">
            <img src="./logo.png" alt="AJP Logo" className="h-20 w-20 mr-3" />
            <div>
              <h1 className="text-2xl font-bold text-green-800 hidden sm:block">
                AJP
              </h1>
              <h2 className="text-lg text-gray-600 hidden sm:block">
                Les volontaires pour la formation en secourisme.
              </h2>
            </div>
          </Link>
          <nav className="space-x-4">
            <a href="/" className="text-gray-800 hover:text-gray-600">
              Accueil
            </a>
            <a href="/apropos" className="text-gray-800 hover:text-gray-600">
              À Propos
            </a>
          </nav>
        </div>
      </header>

      <main className="flex-grow flex flex-col items-center p-6">
        <h1 className="text-2xl font-bold text-green-800 mb-4">
          Merci pour votre engagement !
        </h1>
        <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full">
          <h1 className="text-2xl font-bold text-gray-800 mb-4">
            Bienvenue, {userData.name} {userData.surname}
          </h1>
          <p className="text-gray-600 mb-4">
            Voici les informations concernant votre inscription:
          </p>
          <div className="space-y-2">
            <p className="text-gray-700">
              <span className="font-semibold">Numéro de Téléphone:</span>
              {"+227 "}
              {userData.phone}
            </p>
            <p className="text-gray-700">
              <span className="font-semibold">Région:</span> {userData.region}
            </p>
            <p className="text-gray-700">
              <span className="font-semibold">Département:</span>{" "}
              {userData.departement}
            </p>
            <p className="text-gray-700">
              <span className="font-semibold">Commune:</span> {userData.commune}
            </p>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default ProfilePage;
