import ReactGA from "react-ga4";

const TRACKING_ID = "G-2MQC0477W6"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(TRACKING_ID);

export const logPageView = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
  });
};
