import React from "react";
import { FaFacebook, FaWhatsapp, FaTwitter } from "react-icons/fa";

const ShareButtons = () => {
  const encodedUrl = encodeURIComponent("https://ajp-formation.abassdev.com");

  const shareLinks = {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
    whatsapp: `https://api.whatsapp.com/send?text=${encodedUrl}`,
    twitter: `https://twitter.com/intent/tweet?url=${encodedUrl}`,
  };

  return (
    <div className="flex space-x-4">
      <a href={shareLinks.facebook} target="_blank" rel="noopener noreferrer">
        <FaFacebook size={20} className="text-gray-500 hover:text-gray-700" />
      </a>
      <a href={shareLinks.whatsapp} target="_blank" rel="noopener noreferrer">
        <FaWhatsapp size={20} className="text-gray-500 hover:text-gray-700" />
      </a>
      <a href={shareLinks.twitter} target="_blank" rel="noopener noreferrer">
        <FaTwitter size={20} className="text-gray-500 hover:text-gray-700" />
      </a>
    </div>
  );
};

export default ShareButtons;
