const formatDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false, // pour afficher l'heure au format 24 heures
  };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

export default formatDate;
