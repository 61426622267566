function getDeviceName() {
  const userAgent = navigator.userAgent.toLowerCase();
  let deviceName = "Appareil inconnu.";

  if (userAgent.includes("iphone")) {
    deviceName = "iPhone";
  } else if (userAgent.includes("ipad")) {
    deviceName = "iPad";
  } else if (userAgent.includes("android")) {
    deviceName = "Android";
  } else if (userAgent.includes("windows phone")) {
    deviceName = "Windows Phone";
  } else if (userAgent.includes("windows")) {
    deviceName = "Windows PC";
  } else if (userAgent.includes("mac")) {
    deviceName = "Mac";
  } else if (userAgent.includes("linux")) {
    deviceName = "Linux";
  }

  return deviceName;
}

export default getDeviceName;
