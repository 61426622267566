import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import WhatsAppLink from "./utils/WhatsAppLink";
import Footer from "./Footer";
import formatDate from "./utils/formatDate";

const UserDetailsPage = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/admin-login");
      } else {
        setIsAuthenticated(true);
      }
    };
    checkAuth();
  }, [navigate]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setUser(response.data);
      })
      .catch((err) => {
        console.error("Error fetching user details:", err);
        setError("Erreur lors de la récupération des détails de l'utilisateur");
      });
  }, [userId]);

  const handleDelete = () => {
    if (
      window.confirm("Êtes-vous sûr de vouloir supprimer cet utilisateur ?")
    ) {
      axios
        .delete(`${process.env.REACT_APP_API_URL}/api/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then(() => {
          alert("Utilisateur supprimé avec succès.");
          navigate("/members"); // Redirige vers la liste des utilisateurs après suppression
        })
        .catch((err) => {
          console.error("Error deleting user:", err);
          setError("Erreur lors de la suppression de l'utilisateur");
        });
    }
  };

  if (error) {
    return (
      <div className="container mx-auto px-4 py-8 text-red-600">{error}</div>
    );
  }

  if (!user) {
    return <div className="container mx-auto px-4 py-8">Chargement...</div>;
  }

  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      <header className="bg-white shadow-md">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <Link to="/" className="flex items-center">
            <img src="/logo.png" alt="Logo" className="h-12 w-12 mr-3" />
            <div>
              <h1 className="text-2xl font-bold text-green-800">AJP</h1>
              <h2 className="text-lg text-gray-600">
                Les volontaires pour la formation en secourisme.
              </h2>
            </div>
          </Link>
        </div>
      </header>

      <main className="flex-grow container mx-auto px-4 py-8">
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="relative">
            <svg
              className="w-full h-48 object-cover"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              fill="white"
              aria-hidden="true"
            >
              <rect width="100" height="100" />
              <circle cx="50" cy="50" r="40" fill="white" />
            </svg>
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <svg
                className="w-24 h-24 rounded-full border-4 border-white shadow-md"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 64 64"
                fill="gray"
                aria-hidden="true"
              >
                <g fill="none" stroke="currentColor" strokeWidth="4">
                  <circle cx="32" cy="32" r="30" />
                  <path d="M44 42c-2-4-8-6-12-6s-10 2-12 6" />
                  <circle cx="32" cy="28" r="6" />
                </g>
              </svg>
            </div>
          </div>
          <div className="pt-10 px-6 pb-4">
            <div className="text-center">
              <h1 className="text-3xl font-bold text-gray-900">
                {user.name} {user.surname}
              </h1>
              <p className="text-lg text-gray-600">{user.email}</p>
            </div>
            <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="bg-white p-4 rounded-lg shadow-sm">
                <p className="text-sm font-medium text-gray-500">Téléphone</p>
                <p className="mt-1 text-lg text-gray-900">
                  <WhatsAppLink phoneNumber={user.phone} message="">
                    {user.phone}
                  </WhatsAppLink>
                </p>
              </div>

              <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
                <p className="text-sm font-medium text-gray-500">Région</p>
                <p className="mt-1 text-lg text-gray-900">{user.region}</p>
              </div>
              <div className="bg-white p-4 rounded-lg shadow-sm">
                <p className="text-sm font-medium text-gray-500">Département</p>
                <p className="mt-1 text-lg text-gray-900">{user.departement}</p>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
                <p className="text-sm font-medium text-gray-500">Commune</p>
                <p className="mt-1 text-lg text-gray-900">{user.commune}</p>
              </div>
              <div className="bg-white p-4 rounded-lg shadow-sm">
                <p className="text-sm font-medium text-gray-500">
                  Date d'inscription
                </p>
                <p className="mt-1 text-lg text-gray-900">
                  {formatDate(user.created_at)}
                </p>
              </div>
            </div>
            <div className="mt-6 text-center">
              <button
                onClick={() => navigate(`/membres/edit/${user.id}`)}
                className="bg-green-800 text-white px-4 py-2 rounded-lg shadow-md hover:bg-red-700"
              >
                Mettre à jour
              </button>
            </div>
            <div className="mt-6 text-center">
              <button
                onClick={handleDelete}
                className="bg-red-600 text-white px-4 py-2 rounded-lg shadow-md hover:bg-red-700"
              >
                Supprimer {user.name}
              </button>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default UserDetailsPage;
